exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-design-tips-js": () => import("./../../../src/pages/design-tips.js" /* webpackChunkName: "component---src-pages-design-tips-js" */),
  "component---src-pages-facebook-cover-photo-examples-js": () => import("./../../../src/pages/facebook-cover-photo-examples.js" /* webpackChunkName: "component---src-pages-facebook-cover-photo-examples-js" */),
  "component---src-pages-facebook-cover-photo-size-js": () => import("./../../../src/pages/facebook-cover-photo-size.js" /* webpackChunkName: "component---src-pages-facebook-cover-photo-size-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-post-size-js": () => import("./../../../src/pages/instagram-post-size.js" /* webpackChunkName: "component---src-pages-instagram-post-size-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-linkedin-cover-photo-size-js": () => import("./../../../src/pages/linkedin-cover-photo-size.js" /* webpackChunkName: "component---src-pages-linkedin-cover-photo-size-js" */),
  "component---src-pages-posts-guestposts-js": () => import("./../../../src/pages/posts/guestposts.js" /* webpackChunkName: "component---src-pages-posts-guestposts-js" */),
  "component---src-pages-posts-js": () => import("./../../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-social-media-image-maker-js": () => import("./../../../src/pages/social-media-image-maker.js" /* webpackChunkName: "component---src-pages-social-media-image-maker-js" */),
  "component---src-pages-social-media-templates-js": () => import("./../../../src/pages/social-media-templates.js" /* webpackChunkName: "component---src-pages-social-media-templates-js" */),
  "component---src-pages-tiktok-pfp-js": () => import("./../../../src/pages/tiktok-pfp.js" /* webpackChunkName: "component---src-pages-tiktok-pfp-js" */),
  "component---src-pages-tiktok-profile-picture-size-js": () => import("./../../../src/pages/tiktok-profile-picture-size.js" /* webpackChunkName: "component---src-pages-tiktok-profile-picture-size-js" */),
  "component---src-pages-tiktok-size-js": () => import("./../../../src/pages/tiktok-size.js" /* webpackChunkName: "component---src-pages-tiktok-size-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-twitter-fleets-size-js": () => import("./../../../src/pages/twitter-fleets-size.js" /* webpackChunkName: "component---src-pages-twitter-fleets-size-js" */)
}

